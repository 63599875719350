<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th>Action</vs-th>
        <vs-th sort-key="">Sales Price Code / Name</vs-th>
        <vs-th sort-key="">Valid From / To</vs-th>
        <vs-th sort-key="">Sales Channel</vs-th>
        <vs-th sort-key="">Territory</vs-th>
        <vs-th sort-key="">Distribution Channel</vs-th>
        <vs-th sort-key="">Pricing Group</vs-th>
        <vs-th sort-key="">Division</vs-th>
        <vs-th sort-key="">Customer</vs-th>
        <vs-th sort-key="">Created By</vs-th>
        <vs-th sort-key="">Created At</vs-th>
        <vs-th sort-key="">Updated By</vs-th>
        <vs-th sort-key="">Updated At</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <vs-button
              @click="clickDetail(tr)"
              color="success"
              icon-pack="feather"
              icon="icon-eye"
              size="small"
            />
          </vs-td>
          <vs-td>{{ tr.sales_price_code_name }}</vs-td>
          <vs-td> {{ tr.valid_from_to }} </vs-td>
          <vs-td> {{ tr.sales_channel }} </vs-td>
          <vs-td> {{ tr.territory }} </vs-td>
          <vs-td> {{ tr.distribution_channel }} </vs-td>
          <vs-td> {{ tr.pricing_group }} </vs-td>
          <vs-td> {{ tr.division }} </vs-td>
          <vs-td> {{ tr.customer }} </vs-td>
          <vs-td> {{ tr.created_by }} </vs-td>
          <vs-td>
            {{ convertUTCtoLocal(convertStringToISO(tr.created_at)) }}
          </vs-td>
          <vs-td> {{ tr.updated_by }} </vs-td>
          <vs-td>
            {{ convertUTCtoLocal(convertStringToISO(tr.updated_at)) }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import { convertUTCtoLocal, convertStringToISO } from "@/utils/helper";

const PricingManagementV3Repository = RepositoryFactory.get(
  "pricingManagementV3"
);

export default {
  components: {},
  props: {
    filter: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      table: this.tableDefaultState(),
    };
  },
  methods: {
    convertStringToISO,
    convertUTCtoLocal,
    clickDetail(data) {
      this.$router.push({
        name: "pricing-management-v3-form-id",
        params: { id: data.price_id, isDetail: true, status: "canceled" },
        props: { isDetail: true },
      });
    },
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        status: "canceled",
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    async getData() {
      this.$vs.loading();
      try {
        const params = {
          params: {
            page: this.table.page,
            length: this.table.length,
            order: this.table.order,
            sort: this.table.sort,
            search: this.table.search,
            status: this.table.status,

            ...this.filter,
          },
        };

        const resp = await PricingManagementV3Repository.getListSalesPrice(
          params
        );
        if (resp.code == 200) {
          this.table.total = resp.data.total_record;
          this.table.totalPage = resp.data.total_page;
          this.table.totalSearch = resp.data.total_record_search;
          this.table.length = resp.data.total_record_per_page;
          this.table.data = resp.data.records;
          this.setStartEnd();
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while draft sales price",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
      this.$vs.loading.close();
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    setFilter(data) {
      this.table.page = 1;
      this.filter = data;
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.table.page = val;
        this.handleChangePage(val);
      },
    },
  },
};
</script>
