<template>
  <div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Territory</label>
        <territory @data="setTerritory" :isMultiple="true" />
      </div>
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Distribution Channel</label>
        <DistributionChannel
          @data="setDistrubutionChannel"
          :isMultiple="true"
        />
      </div>
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Pricing Group</label>
        <PricingGroup @data="setPricingGroup" :isMultiple="true" />
      </div>
    </div>

    <div class="mb-6 flex gap-2">
      <vs-button color="success" type="filled" @click="clickFilter">
        Submit Filter
      </vs-button>
    </div>
  </div>
</template>

<script>
import Territory from "@/components/master/Territory.vue";
import DistributionChannel from "@/components/master/DistributionChannel.vue";
import PricingGroup from "@/components/master/PricingGroup.vue";

export default {
  components: {
    Territory,
    DistributionChannel,
    PricingGroup,
  },
  data() {
    return {
      form: {
        territory_ids: [],
        distribution_channel_ids: [],
        pricing_group_ids: [],
      },
    };
  },
  methods: {
    async setDistrubutionChannel(datas) {
      this.form.distribution_channel_ids = [];
      datas.forEach((data) => {
        this.form.distribution_channel_ids.push(data.ID);
      });
    },
    async setPricingGroup(data) {
      this.form.pricing_group_ids = [];
      data.forEach((data) => {
        this.form.pricing_group_ids.push(data.ID);
      });
    },
    async setTerritory(data) {
      this.form.territory_ids = [];
      data.forEach((item) => {
        this.form.territory_ids.push(item.id);
      });
    },
    async clickFilter() {
      this.$emit("data", this.form);
    },
  },
};
</script>
