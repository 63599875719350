<template>
  <div>
    <vx-card title="Sales Price">
      <div>
        <HeaderAction />
      </div>
      <div class="my-4">
        <FilterTable @data="setFilter" />
      </div>
      <vs-tabs>
        <vs-tab label="Draft">
          <div class="con-tab-ejemplo">
            <Draft ref="draft" />
          </div>
        </vs-tab>
        <vs-tab label="Processing">
          <div class="con-tab-ejemplo">
            <Processing ref="processing" />
          </div>
        </vs-tab>
        <vs-tab label="Released">
          <div class="con-tab-ejemplo">
            <Released ref="released" />
          </div>
        </vs-tab>
        <!-- <vs-tab label="Ammend">
          <div class="con-tab-ejemplo">
            <Ammend ref="ammend" />
          </div>
        </vs-tab> -->
        <!-- <vs-tab label="Released">
          <div class="con-tab-ejemplo">
            <Released ref="released" />
          </div>
        </vs-tab> -->
        <vs-tab label="Expired">
          <div class="con-tab-ejemplo">
            <Expired ref="expired" />
          </div>
        </vs-tab>
        <vs-tab label="Rejected">
          <div class="con-tab-ejemplo">
            <Rejected ref="rejected" />
          </div>
        </vs-tab>
        <vs-tab label="Canceled">
          <div class="con-tab-ejemplo">
            <Canceled ref="canceled" />
          </div>
        </vs-tab>
        <vs-tab label="Log Import">
          <div class="con-tab-ejemplo">
            <TableLogImport ref="tableLogImport" :filter="filterTableImport" />
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>
<script>
import Draft from "./draft/index.vue";
import Released from "./released/index.vue";
import Expired from "./expired/index.vue";
import Canceled from "./canceled/index.vue";
import Approved from "./approved/index.vue";
import Rejected from "./rejected/index.vue";
import Processing from "./processing/index.vue";
import FilterTable from "./_components/filter-table.vue";
import HeaderAction from "./_components/header-action.vue";
import Ammend from "./ammend/index.vue";
import TableLogImport from "@/components/import/Table.vue";

export default {
  components: {
    Draft,
    Released,
    Rejected,
    Expired,
    Canceled,
    Approved,
    FilterTable,
    HeaderAction,
    TableLogImport,
    Ammend,
    Processing,
  },
  data() {
    return {
      filterTableImport: {
        type: "price-management-v3",
        source: "OMS",
      },
    };
  },
  methods: {
    setFilter(data) {
      if (this.$refs.draft != undefined) {
        this.$refs.draft.setFilter(data);
      }
      if (this.$refs.released != undefined) {
        this.$refs.released.setFilter(data);
      }
      if (this.$refs.approved != undefined) {
        this.$refs.approved.setFilter(data);
      }
      if (this.$refs.rejected != undefined) {
        this.$refs.rejected.setFilter(data);
      }
      if (this.$refs.expired != undefined) {
        this.$refs.expired.setFilter(data);
      }
      if (this.$refs.canceled != undefined) {
        this.$refs.canceled.setFilter(data);
      }
      if (this.$refs.ammend != undefined) {
        this.$refs.ammend.setFilter(data);
      }
      if (this.$refs.processing != undefined) {
        this.$refs.processing.setFilter(data);
      }
    },
  },
};
</script>
