<template>
  <div>
    <!-- Create & Upload button link -->
    <div class="flex justify-start gap-2 my-2">
      <vs-button
        color="primary"
        type="filled"
        size="medium"
        @click="clickCreate"
      >
        Create
      </vs-button>
      <vs-button
        color="warning"
        type="filled"
        size="medium"
        @click="clickUpload"
      >
        Upload
      </vs-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    clickCreate() {
      this.$router.push({
        name: "pricing-management-v3-form",
        params: { isDetail: false, status: "create" },
      });
    },
    clickUpload() {
      this.$router.push({
        name: "pricing-management-v3-upload-form",
      });
    },
  },
};
</script>
